export const headerNav = [
    // {
    //     title: 'Home',
    //     slug: '/',
    // },
    // {
    //     display: 'Blog',
    //     path: '/blog',
    // },
    // {
    //     title: 'Categories',
    //     slug: '#',
    //     chilldren: localStorage.getItem('categories')
    //         ? JSON.parse(localStorage.getItem('categories'))
    //         : [],
    // },
    {
        title: 'About me',
        slug: '/about',
    },
];
